import axios from 'axios';
import {sendErrorMail} from '../_services/agreements.service';

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000, // indicates, 1000ms ie. 1 second
    headers: {
        "Content-Type": "application/json",
    }
});

httpClient.interceptors.response.use((res) => {
    // if (res.status != 200 ){
    //     const _body = {
    //         subjectError: "Rent Agreement Frontend Api failed - "+ res.status,
    //         errorBody: `Api Response = ${JSON.stringify(res.data)}\n Error = ${res.status} - ${res.statusText}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${payloadToJsonString(res.config.data || "")}\n`
    //     }
    //     sendErrorMail(_body)
    // }
    return res;
}, async function(error) {
    const res = error.response;
    const _body = {
        subjectError: "Rent Agreement Frontend Api failed - "+ res.status,
        errorBody: `Api Response = ${JSON.stringify(res.data)}\n Error = ${res.status} - ${res.statusText}\n AxiosError - ${error.code} : ${error.message}\n BaseUrl = ${res.config.baseURL}\n EndPoint = ${res.config.url}\n RespTime = ${new Date().toLocaleString()}\n Payload = ${payloadToJsonString(res.config.data || "")}\n`
    }
    sendErrorMail(_body)

    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
        originalRequest._retry = true;
        sessionStorage.removeItem("token");
        location.reload();
        return httpClient(originalRequest);
    }
    return Promise.reject(error);
});

function payloadToJsonString(payload){
    if(payload instanceof FormData){
        const object = {};
        formData.forEach((value, key) => {
            object[key] = value.toString();
        });
        return JSON.stringify(object);
      }else{
        return JSON.stringify(payload);
      } 
    
}


export default httpClient;