<template>
  <div class="container">
    <div style="height: 15px; clear: both"></div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12">
        <h4 class="summeryheading">Summary</h4>
        <p>Please Check your details carefully</p>
      </div>
    </div>
    <div class="row row-flex">
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 order2">
        <div class="sumbdrBox">
          <div class="sumhead">
            <div class="finview" v-if="componentName!== 'MumbaiLeasePreview'"><span>Agreement Preview</span></div>
            <div class="finview" v-else> 
              <img src="../../assets/images/bioIcon.svg"> 
              <span>Biometric Details</span> 
            </div>
            <div class="btnAction">
              <button class="btn"  v-if="referrerForm && userRole.toUpperCase() !== 'USER' && componentName!== 'MumbaiLeasePreview' && isPaidAgreement == false" @click="sharingOn = !sharingOn">Share Now</button>
              <router-link
                :to="
                  '/rentalagreement/' +
                  getSource +
                  '/' +
                  getToken +
                  '/' +
                  this.$route.params.id
                "
                class="btn"
              >
                Edit</router-link
              >
              <button class="btn" @click="exportToPDF" v-if="componentName!== 'MumbaiLeasePreview' && isPaidAgreement == false">Download</button>
            </div>
          </div>
          <div
            class="sumBody usSelectNone"
            :class="{ pdfDownload: pdfDownload ? true : false,'waterMark':componentName!== 'MumbaiLeasePreview' }"
            ref="document"
          >
            <component
              :is="componentName"
              :readOnly="true"
              :esignData="previewData"
              :bioMatericData="bioMatericData"
              :walkInBio="walkInBio"
              :currentCity="agreementCity"
              :stamp="true"
              @openBiometric="openBiometric"
            />
          </div>
        </div>
        <div class="sumbdrBox" v-if="componentName == 'MumbaiLeasePreview'">
          <div class="eSign">
              <figure><img src="../../assets/images/e-sign.svg"></figure>
              <div class="esign-text">
                <p class="areeementLetter">Schedule a Biometric Appointment after Payment</p>
                <p class="paymentText">Biometric details of all the participants will be taken at your
              preferred location</p>
              </div>
            </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 usSelectNone order1">
        <div v-if="isPaidAgreement == false" class="sumbdrBox rightBox">
          <p class="totalPrice">
            <span v-if="!validCoupon"> &#8377;{{ stampAmount.toLocaleString() }}</span>
            <span v-else-if="agreementCity == 'Mumbai' || agreementCity == 'Pune'||agreementCity == 'Pune Rural'||agreementCity == 'Mumbai Rural'|| agreementCity =='Thane' || agreementCity == 'Navi Mumbai'"> &#8377;{{ stampAmount.toLocaleString() }}</span>
            <span v-else> &#8377;{{ serviceCharges.toLocaleString() }}</span
            ><br />
            Total Amount
          </p>
          <div class="couponBox" v-if="referrerForm">
          <div class="smplView"><em class="icon-promo-code"></em>
            Apply Coupons
          </div>
          <div class="form-group aplyCpn" v-if="couponApplyed">
              <input type="text" class="form-control" :class="{coupanError:notValidCoupon? true:false}" v-model="couponCode" placeholder="Enter Promo Code">
              <button type="button" class="apply" @click="applyCoupon">Apply</button>            
          </div>
          <div class="form-group CpnAplyed" v-else>
              <input type="text" class="form-control" :value="couponCode" placeholder="Enter Promo Code" disabled="disabled">
              <button type="button" class="CpnRemove" @click="removeCoupon"><em class="icon-delete"></em> </button>           
          </div>
          <p class="CpnText notValid" v-if="notValidCoupon">This Coupon is not Valid</p>
          <p class="CpnText" v-if="couponValue>0">Coupon applied, you save &#8377;{{couponValue}}</p>
          
          <div class="smplView" v-if="couponCodes.length>0">
            Applicable Coupons
          </div>
          <ul class="coupoanList">
            <li class="active" v-for="(couponCodes,indx) in couponCodes" :key="indx">
              <label>
                <input type="radio" v-model="couponCode" :value="couponCodes.Code" @change="applyCoupon">
                <dd class="coponName">
                  <span>{{couponCodes.Code}}</span><br>
                  <p class="terms">Get &#8377;{{amountCommas(couponCodes.Amount)}} discount on agreement creation.</p>
                </dd>
              </label>
            </li>
          </ul>
        </div>
          <div class="priceDtl">
            <h6>Price Details</h6>
            <ul>
              <li>
                <span>Total MRP </span>
                <span v-if="!validCoupon">&#8377;{{ stampAmount.toLocaleString() }}</span>
                <span v-else-if="['Mumbai', 'Pune', 'Thane', 'Navi Mumbai', 'Mumbai Rural', 'Pune Rural'].indexOf(agreementCity)!==-1"> &#8377;{{stampAmount.toLocaleString() }}</span>
                <span v-else> &#8377;{{ serviceCharges.toLocaleString() }}</span>
              </li>
              <!-- <li><span>Convenience Fee</span><span>FREE</span></li> -->
              <li v-if="isBiometricAmount"><span>Biometric Fee</span> <span>&#8377;{{biometricAmount.toLocaleString()}}</span> </li>
              <li v-if="isBiometricAmount"><span class="text-bold"><strong>({{biometricTitle}})</strong></span> <span @click="isBioMetric = true" class="text-success text-bold">Change</span> </li>
              <li v-if="couponValue>0"><span>Total Discount</span><span class="offer">- &#8377;{{couponValue}}</span></li>
              <li>
                <span>Net Payable amount</span
                ><span>&#8377;{{ netPayableAmount.toLocaleString() }}</span>
              </li>
            </ul>
          </div>
          <div class="btnBox">
            <button
              class="btn btn-primary btn-block"
              @click="payNow"
            >
             Confirm Details &amp; Pay &#8377;{{ netPayableAmount.toLocaleString() }}
            </button>
            <!-- <button class="btn btn-primary btn-block" @click="payNowUrl" v-else>
              Confirm Details &amp; Pay &#8377;{{ amount.toLocaleString() }}
            </button> -->
          </div>
        </div>
        <div v-if="isPaidAgreement == true" class="sumbdrBox rightBox">
            <div class="btnBox">
                <button class="btn btn-primary btn-block" @click="submitForEsign">Confirm Details For Esign</button>
            </div>
        </div>
        <div class="sumbdrBox rightBox digitalSign" v-if="agreementCity != 'Mumbai' && agreementCity != 'Pune' && agreementCity !='Thane' && agreementCity != 'Navi Mumbai' ">
          <figure><img src="../../assets/images/previewIcon.svg" /></figure>
          <div class="signHead">
            <h6>Digitally Sign your agreement after payment</h6>
            <p>
              A mail will be triggered to all the participants for e-signing the
              stamped agreement
            </p>
          </div>
          <!-- <div class="signHead" v-if="agreementCity == 'Mumbai' || agreementCity == 'Pune'">
            <h6>Schedule a Biometric Appointment after Payment</h6>
            <p>
              Biometric details of all the participants will be taken at your
              preferred location
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <shareAgreement :sharingOn="sharingOn"></shareAgreement>
    <overlay :openModel="openModel"></overlay>
    <bioMateric  v-if="isBioMetric" :apiAmount = "agreementCharges" :bioMetricIndex="bioMetricIndex" :cityId="cityID" :masterData="masterData" :agreementAmount ="netPayableAmount" :reqData="reqData" :TransactionId="TransactionId"></bioMateric>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
// import html2pdf from "html2pdf.js";
import { defaultView, MumbaiLeasePreview, LeaseAgreement } from "./templates";
import config from '../../../config.json'
import { getItemFromStorage } from "@/_helpers/utility";
import overlay from "./templates/overlay";
import shareAgreement from './shareAgreement'
import bioMateric from './templates/bioMateric.vue'
import {paymentService} from '../../_services/payment.service';
export default {
  data() {
    return {
      isBiometricAmount:false,
      biometricAmount:0,
      biometricTitle:"",
      bioMatericData:"",
      bioMetricIndex:"",
      walkInBio:"",
      cityID:0,
      isBioMetric:false,
      lanlord: "",
      promoCode: "",
      componentName: "defaultView",
      agreementCity: "",
      agreementName: "",
      openModel: "",
      pdfDownload: false,
      reqData: "",
      previewData: "",
      paymentUrl: null,
      couponCode: "",
      couponRefType:"",
      notValidCoupon: false,
      validCoupon: false,
      couponApplyed:true,
      couponValue: 0,
      isRewardAgreement:false,
      serviceCharges:0,
      sharingOn:false,
      userRole:'',
      referrerForm:true,
      TransactionId:'',
      agreementServiceId:'',
      agreementCharges:{},
      isPaidAgreement:false,
      memberCoupons:[]
    };
  },

  name: "agreementPrivew",
  components: {
    MumbaiLeasePreview,
    defaultView,
    LeaseAgreement,
    overlay,
    shareAgreement,
    bioMateric
  },
 asyncComputed:{
   
 },
  computed: {
    ...mapGetters("master", { masterData: "getData" }),
    ...mapState({ state: (state) => state.agreement }),
   policeVerificationAmount:function(){
      const cityNames=["Mumbai","Pune","Pune Rural","Mumbai Rural","Thane","Navi Mumbai"];
      if(cityNames.indexOf(this.agreementCity) !== -1 && this.reqData.package == "Rental Agreement + Police Verification" ){
        return config["policeVerification"+this.agreementCity]
      }else{
        return null;
      }
   },
   stampAmount: function () {
      if (this.serviceCharges>0) {     
        if (["Mumbai", "Pune", "Thane", "Navi Mumbai"].indexOf(this.agreementCity) !== -1 ) {
          let amount = 0;
          for (let i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
            let stampAmount= Math.round(0.0025 *(parseInt(amount) + 0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
             return stampAmount>100?stampAmount+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge); 
          } else {
           let stampamount= Math.round(0.0025 * (parseInt(amount) +0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
              
            return stampamount>100?stampamount+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge); 
          }
        }
        else if(this.agreementCity == "Mumbai Rural" ){
            let amount = 0;
          for (let i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
          let stmpAmount=Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
          //  return stmpAmount>100?stmpAmount+parseInt(config.mumbaiRuralRegistrationCharge):100+parseInt(config.mumbaiRuralRegistrationCharge); 
           return stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge); 
          } else {
            let stampAmount=Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
            return stampAmount>100?stampAmount+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge)
            // return stampAmount>100?stampAmount+parseInt(config.mumbaiRuralRegistrationCharge):100+parseInt(config.mumbaiRuralRegistrationCharge)
          }
        }
         else if(this.agreementCity == "Pune Rural" ){
            let amount = 0;
          for (let i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
          let stmpAmount=Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
          //  return stmpAmount>100?stmpAmount+parseInt(config.puneRuralRegistrationCharge):100+parseInt(config.puneRuralRegistrationCharge); 
           return stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge); 
          } else {
            let stampAmount=Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
            // return stampAmount>100?stampAmount+parseInt(config.puneRuralRegistrationCharge):100+parseInt(config.puneRuralRegistrationCharge)
            return stampAmount>100?stampAmount+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge)
          }
        }
         else {
          if ((["Mumbai", "Pune", "Thane", "Navi Mumbai", "Mumbai Rural", "Pune Rural"].indexOf(this.agreementCity) === -1 ) 
            ){
              if(this.validCoupon){
              return this.serviceCharges-this.couponValue;
            }else{
              return this.serviceCharges;
            }
          }
        }
      }
      return "-";
    },
     
    onlyAgreementAmount: function () {
      if (this.serviceCharges>0) {        
        if (["Mumbai", "Pune", "Thane", "Navi Mumbai"].indexOf(this.agreementCity) !== -1 )
        {
          let amount = 0;
          for (var i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
          let stmpAmount=Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
            return stmpAmount>100?stmpAmount - parseInt(this.couponValue)+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge) - parseInt(this.couponValue);
          } else {
            let stampAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12)))
            );

            return stampAmount>100?stampAmount+parseInt(this.agreementCharges.data.registrationCharge):100+parseInt(this.agreementCharges.data.registrationCharge)
          }
        } 
        else if(this.agreementCity == "Mumbai Rural")
        {
           let amount = 0;
          for (let i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
            let stmpAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));          
           return stmpAmount>100?stmpAmount+this.agreementCharges.data.registrationCharge - parseInt(this.couponValue):100+this.agreementCharges.data.registrationCharge - parseInt(this.couponValue)            
          } else {
            let stmpAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                      (parseInt(this.reqData.contractDuration) / 12)))
                      );           
            return stmpAmount>100?stmpAmount+this.agreementCharges.data.registrationCharge:100+this.agreementCharges.data.registrationCharge
          }

        } 
        else if(this.agreementCity == "Pune Rural"){
           let amount = 0;
          for (let i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
            let stmpAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
           return stmpAmount>100?stmpAmount+ parseInt(this.agreementCharges.data.registrationCharge) - parseInt(this.couponValue):100+this.agreementCharges.data.registrationCharge - parseInt(this.couponValue);
          }
          else 
          {
            let stmpAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                      (parseInt(this.reqData.contractDuration) / 12)))
                      );
            return stmpAmount>100?stmpAmount+ parseInt(this.agreementCharges.data.registrationCharge):100+ parseInt(this.agreementCharges.data.registrationCharge)
            // return stmpAmount>100?stmpAmount+ parseInt(config.puneRuralRegistrationCharge):100+ parseInt(config.puneRuralRegistrationCharge)
          }

        }
        else {
          if (["Mumbai", "Pune", "Thane", "Navi Mumbai", "Mumbai Rural", "Pune Rural"].indexOf(this.agreementCity) === -1 ) 
            {
              if(this.validCoupon){
              return this.serviceCharges-this.couponValue;
            }else{
              return this.serviceCharges;
            }
          }
        }
      }
      return "-";
    },
    netPayableAmount: function () {
      if (this.serviceCharges && this.serviceCharges>0) {     
        if (["Mumbai", "Pune", "Thane", "Navi Mumbai"].indexOf(this.agreementCity) !== -1 ) {
          let amount = 0;
          for (var i = 0; i < this.reqData.rentamount.length; i++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[i].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[i].toMonth) -
                  parseInt(this.reqData.rentamount[i].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
            let stmpAmount = Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
                // return stmpAmount>100?stmpAmount+parseInt(config.registrationCharge) - parseInt(this.couponValue) + parseInt(this.biometricAmount):100+parseInt(config.registrationCharge)- parseInt(this.couponValue) + parseInt(this.biometricAmount)                
                return stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge) - parseInt(this.couponValue) + parseInt(this.biometricAmount):100+parseInt(this.agreementCharges.data.registrationCharge)- parseInt(this.couponValue) + parseInt(this.biometricAmount)                
          } else {
            let stmpAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));                      
               return stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge)+ parseInt(this.biometricAmount):100+ parseInt(this.agreementCharges.data.registrationCharge)+ parseInt(this.biometricAmount)
              //  return stmpAmount>100?stmpAmount+parseInt(config.registrationCharge)+ parseInt(this.biometricAmount):100+ parseInt(config.registrationCharge)+ parseInt(this.biometricAmount)
          }
        } 
        else if(this.agreementCity == "Mumbai Rural"){
          let amount = 0;
          for (let j = 0; j < this.reqData.rentamount.length; j++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[j].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[j].toMonth) -
                  parseInt(this.reqData.rentamount[j].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
            let stmpAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))) );       
                  return stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount):100+parseInt(this.agreementCharges.data.registrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount)
                  // return stmpAmount>100?stmpAmount+parseInt(config.mumbaiRuralRegistrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount):100+parseInt(config.mumbaiRuralRegistrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount)
        } else {
              let stmpAmount = Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
                return stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge)+ parseInt(this.biometricAmount):100+parseInt(this.agreementCharges.data.registrationCharge)+ parseInt(this.biometricAmount)
                // return stmpAmount>100?stmpAmount+parseInt(config.mumbaiRuralRegistrationCharge)+ parseInt(this.biometricAmount):100+parseInt(config.mumbaiRuralRegistrationCharge)+ parseInt(this.biometricAmount)
          }
        } else if(this.agreementCity == "Pune Rural"){
          let amount = 0;
          for (let j = 0; j < this.reqData.rentamount.length; j++) {
            amount =
              amount +
              parseInt(this.reqData.rentamount[j].amount.replace(/,/g, '')) *
                (parseInt(this.reqData.rentamount[j].toMonth) -
                  parseInt(this.reqData.rentamount[j].fromMonth) +
                  1);
          }
          if (this.validCoupon) {
            let stmpAmount= Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))) );       
                  return stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount):100+parseInt(this.agreementCharges.data.registrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount)
                  // return stmpAmount>100?stmpAmount+parseInt(config.puneRuralRegistrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount):100+parseInt(config.puneRuralRegistrationCharge)- parseInt(this.couponValue)+ parseInt(this.biometricAmount)
        } else {
              let stmpAmount = Math.round(
                0.0025 *
                  (parseInt(amount) +
                    0.1 *
                      (parseInt(this.reqData.refundableDeposit.replace(/,/g, '')) *
                        (parseInt(this.reqData.contractDuration) / 12))));
                let result = parseInt(stmpAmount>100?stmpAmount+parseInt(this.agreementCharges.data.registrationCharge)+ parseInt(this.biometricAmount):100+parseInt(this.agreementCharges.data.registrationCharge)+ parseInt(this.biometricAmount))
                // let result = parseInt(stmpAmount>100?stmpAmount+parseInt(config.puneRuralRegistrationCharge)+ parseInt(this.biometricAmount):100+parseInt(config.puneRuralRegistrationCharge)+ parseInt(this.biometricAmount))
                if(isNaN(result))result=result.replace(/\s/g,"");
                return result;
          }
         
        } 
        else {
          if (((this.agreementCity !== "Mumbai" && this.agreementCity !== "Pune" && this.agreementCity!=="Pune Rural"||this.agreementCity !== "Mumbai Rural" || this.agreementCity !=="Thane" || this.agreementCity !=="Navi Mumbai" ) ) 
            ){
              if(this.validCoupon){
              return this.serviceCharges-this.couponValue;
            }else{
              return this.serviceCharges;
            }
          }
        }
      }
      return "-";
    },
    couponCodes:function(){
      let couponCodes = [];      
      const _user = getItemFromStorage("user");
      if(_user.UserType.toUpperCase() !== 'USER' && this.referrerForm){
        const _discount=this.agreementCharges.data && this.agreementCharges.data.discount;
        if((_user.CPlus == true || _user.CPro == true) && _discount){
          if(this.isRewardAgreement && _discount.subcriptionRewards>0){
              couponCodes.push({"Code":_user.UserName,"Amount": _discount.subcriptionRewards,"Description":"You save Rs. "+ _discount.subcriptionRewards});
          }else if(_discount.normaldiscount>0){             
              couponCodes.push({"Code":_user.UserName,"Amount":  _discount.normaldiscount,"Description":"You save Rs. "+ _discount.normaldiscount});
          }
        }
      }
      if(this.memberCoupons && this.memberCoupons.length>0)
      couponCodes = couponCodes.concat(this.memberCoupons);
      return couponCodes;
    },
    getToken: function () {
      return getItemFromStorage("token");
    },
    getSource: function () {
      return getItemFromStorage("source");
    },
  },
  methods: {
    ...mapActions("agreement", ["previewAgreementInfo","downloadPdf"]),
    ...mapActions("coupon", ["checkCplusDiscount","submitSubscriptionAgreement"]),
    ...mapActions("payment", {
      paymentAgreement: "createInvoice",
      paymentStatus1: "paymentStatus1",
    }),
    ...mapActions("coupon", { checkCoupon: "checkCoupon", getCoupons: "getCoupons" }),
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    openBiometric(value){
      this.isBioMetric = true;
      this.bioMetricIndex = value +1;
    },
    async myResolvedValue(cityId,agreementName) {
      const payload={
        "cityId":cityId,
        "agreementType":agreementName
      }
       const AgreementApi = await paymentService.agreementAmountNew(payload);
       if(AgreementApi.status == 200){
        const _data=AgreementApi.data.data;
         this.agreementCharges = AgreementApi.data;
         this.serviceCharges=_data.serviceCharges
       }
       else
       {
        this.agreementCharges = {};
         this.serviceCharges=0;
       }
    },
    async checkRewardAgreement(){
        const user = getItemFromStorage("user");
        let payload={
          userId:user.Id,
          cityId:this.agreementServiceId
        }
     let  res=await this.checkCplusDiscount(payload);
     if(res.data.status){
       this.isRewardAgreement=res.data.isRewardAgreement
     }
    },
    async exportToPDF() {
      this.openModel = "loader";
      const filename=`Agreement_${new Date().getTime()}.pdf`;
      const response = await this.downloadPdf({
          filename,
          "margin": {
              "top": 50,
              "right": 5,
              "bottom": 80,
              "left": 5
          },
          "url":process.env.VUE_APP_PDF_URL+this.$route.params.id+'/1',
          "printBackground" : true
      });
      const data=response['data'].data;
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
      this.openModel = "";
    },
    amountCommas(num){
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    },
    async applyCoupon() {
      const _coupons= this.couponCodes;

      if(!_coupons || _coupons.length==0)
        return;
      const _coupon=_coupons.find(f=>f.Code==this.couponCode);
      if(!_coupon)return;
      const user = getItemFromStorage("user");
      let data = {
        userId: user.Id,
        userType: user.UserType,
        couponCode: this.couponCode,
      };    
      if(_coupon.RefType)
        data.refType=_coupon.RefType;
      
      const response = await this.checkCoupon(data);
      if (response.status) {
        this.validCoupon = response.valid;
        this.couponApplyed= false;
        this.notValidCoupon = false;
        this.couponValue = _coupon.Amount;    
        this.couponRefType = _coupon.RefType || "";    
      } else {
        this.notValidCoupon = true;
        this.couponApplyed= true;
        this.couponValue = 0 ;
        this.couponRefType = "";
      }
    },
    removeCoupon(){
      this.validCoupon = false;
      this.couponApplyed= true;
      this.notValidCoupon = false;
      this.couponValue = 0;
      this.couponCode="";
    },
    async getPreviewData() {
      var data = await this.previewAgreementInfo({ id: this.$route.params.id });
      this.agreementServiceId=data.agreementServiceId
      this.reqData = data.data;
      
      this.TransactionId = data.TransactionId;
      this.cityID = data.cityId
      if (data.paymentURL) {
        this.paymentUrl = data.paymentURL;
      }
      this.isPaidAgreement = data.isPaidAgreement;

      if(data.referredBy !== 1){
        this.referrerForm = false;
      }
      this.previewData = data;
    
      const cityNames=["Mumbai","Pune","Pune Rural","Mumbai Rural","Thane","Navi Mumbai"];
      if (data.status) {
        let pckgename = data.data.package.replace(/ /g, "");
        this.agreementCity = data.data.city;
        this.agreementName = data.data.package;
        if (
          pckgename == "RentalAgreement" ||
          pckgename == "RentalAgreement+PoliceVerification"
        ) {          
          if (cityNames.indexOf(this.agreementCity) !== -1)
            this.componentName = "MumbaiLeasePreview";
          else this.componentName = "LeaseAgreement";
        } else {
          this.componentName = "defaultView";
        }
      }
    
      if(cityNames.indexOf(this.reqData.city) !== -1){
        this.isBioMetric=true;
         return
      }
    },
    payNowUrl() {
      window.location.replace(this.paymentUrl);
    },
    async getUnits() {
      var newReq = await this.previewNow(this.$route.params.id);
      var newData = newReq.data[0];
      for (var landlord = 0; landlord < newData.TxParties.length; landlord++) {
        if (newData.TxParties[landlord].Roles.x.RefName == "Landlord") {
          this.landlordParty.push(newData.TxParties[landlord].Party);
        }
      }
    },
    documentHeight() {
        // var body = document.body;
        // var html = document.documentElement;
        var bodyH = Math.max(document.body.scrollHeight, document.body.offsetHeight,
                document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        window.parent.postMessage({ height: bodyH +1000+ 'px' }, '*');
    },
    async submitForEsign(){
      this.$router.push('/eSign/' + this.$route.params.source + '/' + this.$route.params.token + '/'+this.$route.params.id);
    },
    async payNow() {
      this.openModel = "loader";       
      const user = getItemFromStorage("user");
      //if(['Mumbai', 'Pune', 'Thane', 'Navi Mumbai', 'Mumbai Rural', 'Pune Rural'].indexOf(this.agreementCity) == -1 && this.isRewardAgreement && this.netPayableAmount===0){          
      if(this.netPayableAmount==0){          
          const data={
            "agreementId":this.$route.params.id,
            "userId":user.Id,
            "agreementServiceCityId":this.agreementServiceId,
            "txnDate": new Date(),
            "couponAmount":this.couponValue,
            "couponCode" : this.couponCode,
            "couponRefType" : this.couponRefType
          }
          const res=await this.submitSubscriptionAgreement(data);
          if(res.data.status){
              this.$router.push('/eSign/' + this.$route.params.source + '/' + this.$route.params.token + '/'+this.$route.params.id);
          }else{
              this.openModel = "";
          }
      }else{
          var data = {
              userId:user.Id,
              paymentRefNo: this.$route.params.id,
              description: "Rent Agreement",
              amount: this.netPayableAmount,
              stampAmount:(['Mumbai', 'Pune', 'Thane', 'Navi Mumbai', 'Mumbai Rural', 'Pune Rural'].indexOf(this.agreementCity) !== -1)? parseInt(this.stampAmount):null,
              policeVerificationAmount:parseInt(this.policeVerificationAmount)        
          };
          if(this.biometricAmount){
            data.biometricAmount=parseInt(this.biometricAmount)
          }
          if (this.validCoupon) {
            data.agreementServiceCityId = this.agreementServiceId;
            data.isRewardAgreement=this.isRewardAgreement
            data.couponCode = this.couponCode;
            data.couponAmount = this.couponValue;
            data.couponRefType = this.couponRefType;
            data.txnDate =new Date();
          }
          await this.paymentAgreement(data);
          this.openModel = "";
          window.parent.postMessage({ height: 100+'vh' }, '*');
      }
    },
  },
  mounted(){    
    this.documentHeight();
    window.parent.postMessage({
        scrollTo: {
            top: 0,
            // behaviour: "smooth"
        }
    }, '*');
    this.$root.$on('sharingOnNow',(data)=>{
      this.sharingOn = data;
    });
    this.$root.$on('loaderOpen',(data)=>{
      this.openModel = data;
    });
    this.$root.$on('bioMatericAmount',(data)=>{
      this.isBiometricAmount = true;
      if(data.biometricTitle == 'WalkIn Biometric'){
        this.walkInBio = data.partiesAddress;
        this.bioMatericData = '';
      }else{
        this.bioMatericData = data.partiesAddress;
        this.walkInBio = '';
      }
      this.biometricAmount = data.amount;
      this.biometricTitle = data.biometricTitle;
    });
  },
  async beforeMount() {    
     await this.getPreviewData();
     await this.checkRewardAgreement()
    this.$root.$on('isBioMetric', (data) => {
      this.isBioMetric = data;
    });
    this.$root.$on('openModel', (data) => {
      this.openModel = data;
    });
    await this.myResolvedValue(this.cityID,this.agreementName);  
    //const paymentStatus1 = await this.paymentStatus1(this.$route.params.id);
    // if (paymentStatus1) {
    //   window.parent.postMessage({ type: "paid" }, "*");
    // }
    const _user = getItemFromStorage("user");
    const data = {
        fromSource : _user.Source,
        userType: _user.UserType,
        userId: _user.Id,
        countryCode : _user.Countrycode || "91",
        mobileNo:  _user.Mobile,
        mongoUserId : _user.UserId
    };
    const response = await this.getCoupons(data);
    if(response && response.status)
    {
      this.memberCoupons=response.data && response.data.filter(f=>f.couponAmount>0)
      .map(m=>{ 
        return {
          Code : m.couponCode,
          Amount : this.serviceCharges < m.couponAmount && this.serviceCharges || m.couponAmount,
          Description : `You save Rs. ${this.serviceCharges < m.couponAmount && this.serviceCharges || m.couponAmount}`,
          RefType : 'RENT'
        }
      }) || [];
    } 
    this.userRole = _user.UserType;
    // window.parent.postMessage({ height: 100+'vh' }, '*');
  },
};
</script>