import { agreementInfo, downloadPdf, shareRentalAgreemnt, updateReferrer, previewAgreementInfo, getCities, getLocalities, getSocieties, getUserInfoByNumber, completeAgreementInfo, showDocumentPath } from "@/_services/agreements.service"


const state = {
    data: {}
}

// const getters = {}

const actions = {
    async agreementInfo({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await agreementInfo(data);
            if (response.data && response.data["status"]) {
                commit('AgreementSuccess', response.data["data"].TransactionId);
                // dispatch('alert/success', "Agreement Party Details Updated successfully", { root: true });
                return { "status": true, "data": response.data["data"].TransactionId }
            } else {
                commit('AgreementFailure', data.id);
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false };
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', message, { root: true });
        }
    },
    async previewAgreementInfo({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await previewAgreementInfo(data);
            // console.log(response.status,)
            if(response.status===202){
                commit('AgreementFailure', data.id);
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
            if (response.data && response.data["status"]) {
                commit('AgreementSuccess', response.data["data"].TransactionId);
                let isPaidAgreement=false;
                const data=response.data["data"];
                if(data.paymentInfoData && data.paymentInfoData.txnRefNo && data.paymentInfoData.txnRefNo.length>5)
                    isPaidAgreement=true;
                // console.log(response.data)
                return { "status": true, isPaidAgreement, "data": response.data["data"].agreementData, "cityId": response.data["data"].cityId, "TransactionId": response.data["data"].TransactionId, "createdBy": response.data["data"].createdBy, "referredBy": response.data["data"].referredBy, "docPath": response.data["data"].docPath, "biometricData": response.data["data"].supportingAddressId, "esignInfoData": response.data["data"].esignInfoData, "agreementServiceId": response.data["data"].agreementServiceId, "agreementStatus": response.data["data"].agreementStatus, "paymentURL": response.data["data"].paymentURL }
            } else {
                commit('AgreementFailure', data.id);
                // console.log(response.data["message"],"---------------");
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (err) {
            console.error(err);
            let message = err.message;
            if (!message)
                message = err;
            dispatch('alert/error', message, { root: true });
        }
    },
    async completeAgreementInfo({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await completeAgreementInfo(data);
            // console.log(response)
            if (response.data && response.data["status"]) {
                // commit('AgreementSuccess', response.data["data"].TransactionId);
                // console.log(response.data)
                return { "status": true, "data": response.data["data"].txData, "count": response.data["data"].count }
            } else {
                // commit('AgreementFailure', data.id);
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', message, { root: true });
        }
    },
    async showDocumentPath({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await showDocumentPath(data);
            if (response.data && response.data["status"]) {
                return { "status": true, "url": response.data["url"] }
            } else {
                // commit('AgreementFailure', data.id);
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', message, { root: true });
        }
    },

    async getCities({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await getCities(data);
            if (response.data && response.data['Cities']) {
                return { "status": true, "data": response.data };
            } else {
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async getLocalities({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await getLocalities(data);
            if (response.data.Localities && response.data.Localities.length > 0) {
                return { "status": true, "data": response.data['Localities'] };
            } else {
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async getSocieties({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await getSocieties(data);
            if (response.data.Societies && response.data.Societies.length > 0) {
                return { "status": true, "data": response.data['Societies'] };
            } else {
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async getUserInfoByNumber({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await getUserInfoByNumber(data);
            // console.log(response.data)
            if (response.data && response.data["status"]) {
                // commit('AgreementSuccess', response.data);
                return { "status": true, "data": response.data.data, "count": response.data.count }
            } else {
                // dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async downloadPdf({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await downloadPdf(data);
            // console.log(response.data)
            // if (response.data && response.data["status"]) {
            // commit('AgreementSuccess', response.data);
            return response;
            // } else {
            // dispatch('alert/error', response.data["message"], { root: true });
            // return { "status": false }
            // }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async shareRentalAgreemnt({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await shareRentalAgreemnt(data);
            // console.log(response.data)
            if (response.data && response.data["status"]) {
                // commit('AgreementSuccess', response.data);
                return { "status": true, "url": response.data["url"] }
            } else {
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
    async updateReferrer({ dispatch, commit }, data) {
        try {
            commit('AgreementRequest');
            var response = await updateReferrer(data);
            // console.log(response.data)
            if (response.data && response.data["status"]) {
                // commit('AgreementSuccess', response.data);
                return { "status": true }
            } else {
                dispatch('alert/error', response.data["message"], { root: true });
                return { "status": false }
            }
        } catch (er) {
            console.error(er);
            let message = er.message;
            if (!message)
                message = er;
            dispatch('alert/error', "Something went wrong", { root: true });
        }
    },
}


const mutations = {
    AgreementRequest(state) {
        state.status = { requesting: true };
    },
    AgreementSuccess(state, Id) {
        state.status = { TransactionId: Id };
    },
    AgreementFailure(state, Id) {
        state.status = { requesting: false, TransactionId: Id };
        if (Id && Id !== '' && Id !== null) {
            state.status.TransactionId = Id;
        }
    },
}

export const agreement = {
    namespaced: true,
    state,
    // getters,
    actions,
    mutations
}