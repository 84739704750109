import httpClient from '@/_helpers/http-client';
import { authHeader } from "../_helpers/auth-header"

const agreementInfo = async(data) => {
    var response = await httpClient.post('/create-agreementInfo', data, authHeader());
    return response;
}
const previewAgreementInfo = async(data) => {
    var response = await httpClient.post('/agreement-preview', data);
    return response;
}
const completeAgreementInfo = async(data) => {
    var response = await httpClient.post('/complete-agreement-info', data, authHeader());
    return response;
}
const showDocumentPath = async(data) => {
    var response = await httpClient.post('/show-download-link', data);
    return response;
}

const getCities = async() => {
    var response = await httpClient.get('/getCities', authHeader());
    return response;
}
const getLocalities = async(data) => {
    var response = await httpClient.post('/getLocalities', { "data": data }, authHeader());
    return response;
}
const getSocieties = async(data) => {
    var response = await httpClient.post('/getSocieties', { "data": data }, authHeader());
    return response;
}
const getUserInfoByNumber = async(data) => {
    var response = await httpClient.post('/complete-agreements-by-user', data, authHeader());
    return response;
}
const downloadPdf = async(data) => {
    var response = await httpClient.post('/generate-pdf', data);
    return response;
}
const shareRentalAgreemnt = async(data) => {
    var response = await httpClient.post('/share-rental-agreement', data);
    return response;
}
const updateReferrer = async(data) => {
    var response = await httpClient.post('/update-referrer', data);
    return response;
}
const sendErrorMail = async(data) => {
    var response = await httpClient.post('/sendErrorMail', data);
    return response;
}


export {
    agreementInfo,
    downloadPdf,
    previewAgreementInfo,
    getCities,
    getLocalities,
    getSocieties,
    getUserInfoByNumber,
    completeAgreementInfo,
    showDocumentPath,
    shareRentalAgreemnt,
    updateReferrer,
    sendErrorMail
};